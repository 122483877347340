<template>
    <div v-loading.fullscreen="page_info.page_data_loading">
        <a-card :title="title" :bodyStyle="{ padding: '0' }" :headStyle="{ padding: '0 10px', textAlign: 'center' }">
            <div class="d-flex pt-3 pb-3 justify-content-between demo-form-inline w-100">
                <el-form :inline="true" class="d-flex" @submit.native.prevent>
                    <el-form-item label="Model Name" v-if="tableModelFilter" class="ms-3">
                        <el-input v-model="tableT.filter_model" placeholder="" clearable @change.enter="filterTableData">
                            <template #prepend>
                                <el-icon @click="open_multiline_box()">
                                    <MoreFilled />
                                </el-icon>
                            </template>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="Sort By" v-if="tableModelFilter" class="ms-3">
                        <el-select v-model="tableT.filter_model_sort_by" collapse-tags collapse-tags-tooltip
                            placeholder="Select" @change="handleModelSortBy" class="ms-3">
                            <el-option v-for="(item, index) in tableT.model_sort_by_option" :key="index" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item v-else-if="tableTips">
                        <el-text class="ps-3 text-danger">* {{ tableTips }}</el-text>
                    </el-form-item>
                    <el-form-item v-else>
                        <span> </span>
                    </el-form-item>
                </el-form>

                <div>
                    <el-icon class="fs-3 mt-1 mb-1 me-3 cursor-pointer text-end" title="export excel"
                        @click="handleFullScreen()">
                        <FullScreen />
                    </el-icon>
                    <el-icon class="fs-3 mt-1 mb-1 me-3 cursor-pointer text-end" title="export excel"
                        @click="handleExportExcel()">
                        <Download />
                    </el-icon>
                </div>
            </div>

            <!-- show-summary sum-text="Total" -->
            <el-table :id="table_id" :data="tableT.tableData" :max-height="height" show-summary
                :header-row-class-name="headStyle" :summary-method="getSummaries"
                style="width: 100%; border-top: solid #f0f0f0; border-width: 1px 0 0 0" :row-key="setTableRowKey"
                :default-expand-all="false" @sort-change="sortChange" cell-class-name="t-cell"
                header-cell-class-name="t-cell">

                <!-- 多级表头 -->
                <el-table-column v-if="hasChildTable" :label="p_item.label" align="center" :prop="p_item.column"
                    :width="p_item.width" :min-width="p_item.min_width" v-for="(p_item, index) in columns"
                    :fixed="p_item.fixed" :sortable="isEmpty(p_item.childTable)">
                    <template #default="{ row, column, $index }" v-if="p_item.childTable == undefined">
                        <span>{{ row[p_item.column] }}</span>
                    </template>

                    <el-table-column v-if="p_item.childTable" v-for="(item, c_index) in p_item.childTable" align="center"
                        :key="c_index" :label="item.label" :prop="item.column" :width="item.width"
                        :min-width="item.min_width" sortable>
                        <template #header="headerScope">
                            {{ headerScope.column.label }}
                        </template>
                        <template #default="{ row, column, $index }">
                            <span v-if="item.dataStyle == 'text'">
                                <span>
                                    {{ row[item.column] }}
                                </span>
                                <div class="d-flex pe-3 align-items-center">
                                    <img class="ms-2" :src="amazon_ads_icon"
                                        v-if="(item.column == 'model' || item.column == 'so_id') && ((row.ads_state_icon || '').split(',') || []).includes('AMAZON')"
                                        style="width:24px" />
                                    <img class="ms-2" :src="wayfair_ads_icon"
                                        v-if="(item.column == 'model' || item.column == 'so_id') && ((row.ads_state_icon || '').split(',') || []).includes('LEXHL')"
                                        style="width:24px" />
                                    <img class="ms-2" :src="overstock_ads_icon"
                                        v-if="(item.column == 'model' || item.column == 'so_id') && ((row.ads_state_icon || '').split(',') || []).includes('OVSTK')"
                                        style="width:24px" />
                                    <div class="ms-2 ads_promo_box"
                                        v-if="(item.column == 'model' || item.column == 'so_id') && ((row.ads_promo_icon || '').split(',') || []).includes('AMAZON')">
                                        A
                                    </div>
                                    <div class="ms-2 ads_promo_box"
                                        v-if="(item.column == 'model' || item.column == 'so_id') && ((row.ads_promo_icon || '').split(',') || []).includes('LEXHL')">
                                        W
                                    </div>
                                    <div class="ms-2 ads_promo_box"
                                        v-if="(item.column == 'model' || item.column == 'so_id') && ((row.ads_promo_icon || '').split(',') || []).includes('OVSTK')">
                                        O
                                    </div>
                                </div>
                            </span>
                            <span v-else-if="item.dataStyle == 'integer'">{{
                                row[item.column]
                            }}</span>
                            <span v-else-if="item.dataStyle == 'money'">{{
                                $filters.moenyFmt(
                                    $filters.NumericTothreeDigit(row[item.column], 2)
                                )
                            }}</span>
                            <span v-else-if="item.dataStyle == 'percent'">{{
                                $filters.percentFmt(row[item.column])
                            }}</span>
                            <span v-else-if="item.dataStyle == 'clickLink'">
                                <el-link type="primary" @click="viewSkusByCategory(item, row)" v-if="props.tableIndex == 3">
                                    {{ row[item.column] }}
                                </el-link>
                                <el-link type="primary" @click="viewLink(item, row)" v-else-if="props.tableIndex == 4">
                                    {{ row[item.column] }}
                                </el-link>
                            </span>
                            <span v-else>{{ row[item.column] }}</span>
                        </template>
                    </el-table-column>
                </el-table-column>

                <!-- 一级表头 -->
                <el-table-column v-else v-for="(item, index) in columns" :key="index" align="center" :prop="item.column"
                    :label="item.label" :width="item.width" :min-width="item.min_width" sortable :fixed="item.fixed"
                    :class-name="item.class_name">
                    <template #header="headerScope">
                        {{ headerScope.column.label }}
                    </template>
                    <template #default="{ row, column, $index }">
                        <span v-if="item.dataStyle == 'text'">
                            <span>
                                {{ row[item.column] }}
                            </span>
                            <div class="d-flex pe-3 align-items-center">
                                <img class="ms-2" :src="amazon_ads_icon"
                                    v-if="(item.column == 'model' || item.column == 'so_id') && ((row.ads_state_icon || '').split(',') || []).includes('AMAZON')"
                                    style="width:24px" />
                                <img class="ms-2" :src="wayfair_ads_icon"
                                    v-if="(item.column == 'model' || item.column == 'so_id') && ((row.ads_state_icon || '').split(',') || []).includes('LEXHL')"
                                    style="width:24px" />
                                <img class="ms-2" :src="overstock_ads_icon"
                                    v-if="(item.column == 'model' || item.column == 'so_id') && ((row.ads_state_icon || '').split(',') || []).includes('OVSTK')"
                                    style="width:24px" />
                                <div class="ms-2 ads_promo_box"
                                    v-if="(item.column == 'model' || item.column == 'so_id') && ((row.ads_promo_icon || '').split(',') || []).includes('AMAZON')">
                                    A
                                </div>
                                <div class="ms-2 ads_promo_box"
                                    v-if="(item.column == 'model' || item.column == 'so_id') && ((row.ads_promo_icon || '').split(',') || []).includes('LEXHL')">
                                    W
                                </div>
                                <div class="ms-2 ads_promo_box"
                                    v-if="(item.column == 'model' || item.column == 'so_id') && ((row.ads_promo_icon || '').split(',') || []).includes('OVSTK')">
                                    O
                                </div>
                            </div>
                        </span>
                        <span v-else-if="item.dataStyle == 'integer'">{{
                            row[item.column]
                        }}</span>
                        <span v-else-if="item.dataStyle == 'money'">{{
                            $filters.moenyFmt(
                                $filters.NumericTothreeDigit(row[item.column], 2)
                            )
                        }}</span>
                        <span v-else-if="item.dataStyle == 'percent'">{{
                            $filters.percentFmt(row[item.column])
                        }}</span>
                        <span v-else>{{ row[item.column] }}</span>
                    </template>
                </el-table-column>
            </el-table>

            <el-row class="mt-2 mb-2 pe-2 flex-row-reverse">
                <el-pagination background layout="prev, pager, next" :current-page="pageInfo.current_page"
                    :page-size="pageInfo.page_size" :total="pageInfo.total" @current-change="handleCurrentChange" />
            </el-row>
        </a-card>

        <el-dialog v-model="page_info.skusDetailDialogVisible" :fullscreen="page_info.skusDetailFullScreen"
            @open="openDialog()" @close="closeDialog('skusDetailDialogVisible')">
            <template #header="{ close, titleId, titleClass }">
                <div class="my-header d-flex justify-content-between align-items-center">
                    <div :id="titleId">Detail Report</div>

                    <div>
                        <el-icon class="el-icon fs-6 mt-1 mb-1 me-3 cursor-pointer text-end text-black-50"
                            title="full screen" @click="page_info.skusDetailFullScreen = !page_info.skusDetailFullScreen">
                            <FullScreen />
                        </el-icon>

                        <el-icon class="el-icon fs-6 mt-1 mb-1 me-3 cursor-pointer text-end text-black-50"
                            title="export excel" @click="handleExportSKUDetailsExcel()">
                            <Download />
                        </el-icon>
                    </div>
                </div>
            </template>
            <div>Crawling updated on {{ page_info.skusDetailCrawlingUpdateTime }}</div>
            <el-table :data="page_info.skusDetailDatas" :header-row-class-name="headStyle" cell-class-name="t-cell" stripe
                style="width:100%" :max-height="!page_info.skusDetailFullScreen?'350':'85vh'">
                <el-table-column property="model" label="Model" sortable />
                <el-table-column property="sell_name" label="Sell Name" sortable />
                <el-table-column property="model_price" label="Price" sortable />
                <el-table-column property="freight_class" label="Freight Type" sortable />
                <el-table-column property="channel_status" label="Active" sortable />
            </el-table>
        </el-dialog>

        <el-dialog v-model="page_info.tableDataDialogVisible" :fullscreen="page_info.tableDataFullScreen"
            @open="openDialog()" @close="closeDialog('tableDataDialogVisible')">
            <el-table :data="tableT.tableData" show-summary
                :header-row-class-name="headStyle" :summary-method="getSummaries"
                style="width:100%" :max-height="!page_info.tableDataFullScreen?'350':'85vh'"
                :row-key="setTableRowKey" 
                :default-expand-all="false" @sort-change="sortChange" cell-class-name="t-cell"
                header-cell-class-name="t-cell">

                <!-- 多级表头 -->
                <el-table-column v-if="hasChildTable" :label="p_item.label" align="center" :prop="p_item.column"
                    v-for="(p_item, index) in columns"
                    :fixed="p_item.fixed" :sortable="isEmpty(p_item.childTable)">
                    <template #default="{ row, column, $index }" v-if="p_item.childTable == undefined">
                        <span>{{ row[p_item.column] }}</span>
                    </template>

                    <el-table-column v-if="p_item.childTable" v-for="(item, c_index) in p_item.childTable" align="center"
                        :key="c_index" :label="item.label" :prop="item.column"
                         sortable>
                        <template #header="headerScope">
                            {{ headerScope.column.label }}
                        </template>
                        <template #default="{ row, column, $index }">
                            <span v-if="item.dataStyle == 'text'">
                                <span>
                                    {{ row[item.column] }}
                                </span>
                                <div class="d-flex pe-3 align-items-center">
                                    <img class="ms-2" :src="amazon_ads_icon"
                                        v-if="(item.column == 'model' || item.column == 'so_id') && ((row.ads_state_icon || '').split(',') || []).includes('AMAZON')"
                                        style="width:24px" />
                                    <img class="ms-2" :src="wayfair_ads_icon"
                                        v-if="(item.column == 'model' || item.column == 'so_id') && ((row.ads_state_icon || '').split(',') || []).includes('LEXHL')"
                                        style="width:24px" />
                                    <img class="ms-2" :src="overstock_ads_icon"
                                        v-if="(item.column == 'model' || item.column == 'so_id') && ((row.ads_state_icon || '').split(',') || []).includes('OVSTK')"
                                        style="width:24px" />
                                    <div class="ms-2 ads_promo_box"
                                        v-if="(item.column == 'model' || item.column == 'so_id') && ((row.ads_promo_icon || '').split(',') || []).includes('AMAZON')">
                                        A
                                    </div>
                                    <div class="ms-2 ads_promo_box"
                                        v-if="(item.column == 'model' || item.column == 'so_id') && ((row.ads_promo_icon || '').split(',') || []).includes('LEXHL')">
                                        W
                                    </div>
                                    <div class="ms-2 ads_promo_box"
                                        v-if="(item.column == 'model' || item.column == 'so_id') && ((row.ads_promo_icon || '').split(',') || []).includes('OVSTK')">
                                        O
                                    </div>
                                </div>
                            </span>
                            <span v-else-if="item.dataStyle == 'integer'">{{
                                row[item.column]
                            }}</span>
                            <span v-else-if="item.dataStyle == 'money'">{{
                                $filters.moenyFmt(
                                    $filters.NumericTothreeDigit(row[item.column], 2)
                                )
                            }}</span>
                            <span v-else-if="item.dataStyle == 'percent'">{{
                                $filters.percentFmt(row[item.column])
                            }}</span>
                            <span v-else-if="item.dataStyle == 'clickLink'">
                                <el-link type="primary" @click="viewSkusByCategory(item, row)" v-if="props.tableIndex == 3">
                                    {{ row[item.column] }}
                                </el-link>
                                <el-link type="primary" @click="viewLink(item, row)" v-else-if="props.tableIndex == 4">
                                    {{ row[item.column] }}
                                </el-link>
                            </span>
                            <span v-else>{{ row[item.column] }}</span>
                        </template>
                    </el-table-column>
                </el-table-column>

                <!-- 一级表头 -->
                <el-table-column v-else v-for="(item, index) in columns" :key="index" align="center" :prop="item.column"
                    :label="item.label" sortable :fixed="item.fixed"
                    :class-name="item.class_name">
                    <template #header="headerScope">
                        {{ headerScope.column.label }}
                    </template>
                    <template #default="{ row, column, $index }">
                        <span v-if="item.dataStyle == 'text'">
                            <span>
                                {{ row[item.column] }}
                            </span>
                            <div class="d-flex pe-3 align-items-center">
                                <img class="ms-2" :src="amazon_ads_icon"
                                    v-if="(item.column == 'model' || item.column == 'so_id') && ((row.ads_state_icon || '').split(',') || []).includes('AMAZON')"
                                    style="width:24px" />
                                <img class="ms-2" :src="wayfair_ads_icon"
                                    v-if="(item.column == 'model' || item.column == 'so_id') && ((row.ads_state_icon || '').split(',') || []).includes('LEXHL')"
                                    style="width:24px" />
                                <img class="ms-2" :src="overstock_ads_icon"
                                    v-if="(item.column == 'model' || item.column == 'so_id') && ((row.ads_state_icon || '').split(',') || []).includes('OVSTK')"
                                    style="width:24px" />
                                <div class="ms-2 ads_promo_box"
                                    v-if="(item.column == 'model' || item.column == 'so_id') && ((row.ads_promo_icon || '').split(',') || []).includes('AMAZON')">
                                    A
                                </div>
                                <div class="ms-2 ads_promo_box"
                                    v-if="(item.column == 'model' || item.column == 'so_id') && ((row.ads_promo_icon || '').split(',') || []).includes('LEXHL')">
                                    W
                                </div>
                                <div class="ms-2 ads_promo_box"
                                    v-if="(item.column == 'model' || item.column == 'so_id') && ((row.ads_promo_icon || '').split(',') || []).includes('OVSTK')">
                                    O
                                </div>
                            </div>
                        </span>
                        <span v-else-if="item.dataStyle == 'integer'">{{
                            row[item.column]
                        }}</span>
                        <span v-else-if="item.dataStyle == 'money'">{{
                            $filters.moenyFmt(
                                $filters.NumericTothreeDigit(row[item.column], 2)
                            )
                        }}</span>
                        <span v-else-if="item.dataStyle == 'percent'">{{
                            $filters.percentFmt(row[item.column])
                        }}</span>
                        <span v-else>{{ row[item.column] }}</span>
                    </template>
                </el-table-column>
            </el-table>

            <el-row class="mt-2 mb-2 pe-2 flex-row-reverse">
                <el-pagination background layout="prev, pager, next" :current-page="pageInfo.current_page"
                    :page-size="pageInfo.page_size" :total="pageInfo.total" @current-change="handleCurrentChange" />
            </el-row>
        </el-dialog>

        <!-- Multiline -->
		<el-dialog v-model="page_info.dialogMultilineVisible" :title="page_info.dialogMultilineTitle" :close-on-press-escape="false"
			:close-on-click-modal="false" width="300px">
			<span class="text-danger">{{ page_info.dialogMultilineNote }}</span>

			<el-input type="textarea" :rows="8" v-model="page_info.multiline_box" @input="checkMutilineInput">
			</el-input>

			<template #footer>
				<div>
					<el-button type="primary" @click="saveMultiline">OK</el-button>
					<el-button type="primary" @click="page_info.dialogMultilineVisible = false;">Cancel</el-button>
				</div>
			</template>
		</el-dialog>
    </div>
</template>

<script setup>
import { ref, reactive, watch, defineProps, toRaw } from "vue";
import { dataToExcel } from '@/utils/htmlToExcel.js'
import { isEmpty, isNotEmpty, moenyFmt, NumericTothreeDigit, percentFmt, isNumber, toNumber, collectionOrderBy } from '@/utils/util.js'
import moment from 'moment'
import { ElMessage } from "element-plus";
import { icon } from "@fortawesome/fontawesome-svg-core";
import { getSkusByCategoryDetail } from "@/api/dashboard";

let tableT = reactive({
    pageTableData: [],
    tableData: [],
    filter_model: '',
    filter_model_sort_by: 'bestselling',
    model_sort_by_option: [{
        label: "Bestselling",
        value: "bestselling",
    },
    {
        label: "New to Old",
        value: "new_to_od",
    }, {
        label: "A - Z",
        value: "a_z",
    }, {
        label: "Z - A",
        value: "z_a",
    }]
})
let table_id = ref(null)

let amazon_ads_icon = require("@/assets/amazon_ads_icon.png")
let wayfair_ads_icon = require("@/assets/wayfair_ads_icon.png")
let overstock_ads_icon = require("@/assets/overstock_ads_icon.png")

const pageInfo = reactive({
    current_page: 1,
    page_size: 50,
    total: 0
})

const page_info = reactive({
    page_data_loading: false,

    skusDetailColumns : [{
        "label": "Model",
        "column": "model",
        "key": "model",
        "dataStyle": "text",
    },{
        "label": "Sell Name",
        "column": "sell_name",
        "key": "sell_name",
        "dataStyle": "text",
    },{
        "label": "Price",
        "column": "model_price",
        "key": "model_price",
        "dataStyle": "money",
    },{
        "label": "Freight Type",
        "column": "freight_class",
        "key": "freight_class",
        "dataStyle": "text",
    },{
        "label": "Active",
        "column": "channel_status",
        "key": "channel_status",
        "dataStyle": "text",
    }],
    skusDetailDatas: [],
    skusDetailDialogVisible: false,
    skusDetailFullScreen: false,
    skusDetailCrawlingUpdateTime:'',

    //tableDataDialogVisible
    tableDataDialogVisible: false,
    tableDataFullScreen: true,

    /* dialogMultiline */
    dialogMultilineVisible: false,
    dialogMultilineTitle: 'Please input Models',
    dialogMultilineNote: 'One Model per line',
    multiline_type: 1,
    multiline_box: '',
})

const props = defineProps({
    tableIndex: { type: Number, default: 0 },
    columns: {
        type: Array,
        default: []
    },
    data: {
        type: Array,
        default: []
    },
    title: {
        type: String,
        default: ''
    },
    totalData: {
        type: Array,
        default: []
    },
    height: {
        type: Number,
        default: 400
    },
    needExport: {
        type: Boolean,
        default: true
    },
    tableModelFilter: {
        type: Boolean,
        default: false
    },
    tableTips: {
        type: String,
        default: ''
    },
    hasChildTable: {
        type: Boolean,
        default: false
    }
})

watch(() => props.data, (nVal) => {
    pageInfo.total = props.data.length
    const page_size = pageInfo.page_size
    tableT.pageTableData = props.data.slice()

    let tableData_show = tableT.pageTableData.slice(0, page_size)
    tableT.tableData = tableData_show
    if (isNotEmpty(nVal)) {
        table_id = 'ngTable_' + props.tableIndex
    }
}, {
    // 里边第三个参数
    deep: true,   // 深度监听
    immediate: false // 立即监听 (一进入页面就会监听到)
})

function checkAdsModelIcon(item) {
    const is_ads_model = item.is_ads_model
    const client_id = item.client_id

    let is_amazon = (client_id.split(',') || []).includes('AMAZON')
    let is_lexhl = (client_id.split(',') || []).includes('LEXHL')
    let is_ovstk = (client_id.split(',') || []).includes('OVSTK')

    if (is_ads_model && is_amazon && icon_type == 'amazon_icon') {
        return true
    }

    if (is_ads_model && is_lexhl && icon_type == 'lexhl_icon') {
        return true
    }

    if (is_ads_model && is_ovstk && icon_type == 'ovstk_icon') {
        return true
    }
    return false
}

// 设置表格中每行的唯一ID
function openDialog(){
    document.body.style.overflow = 'hidden'
}
function closeDialog(dialogId){
    if(dialogId === "skusDetailDialogVisible"){
        page_info.skusDetailDialogVisible = false
    }else if(dialogId ===  "tableDataDialogVisible"){
        page_info.tableDataDialogVisible = false
    }

    if(page_info.tableDataDialogVisible || page_info.skusDetailDialogVisible){
        document.body.style.overflow = 'hidden'
    }else{
        document.body.style.overflow = ''
    }
}
function setTableRowKey(row) {
    return row.id
}
function headStyle({ rowIndex }) {
    if (rowIndex === 0) {
        return "headerStyle";
    }
}
function sortChange(column) {
    let datas = props.data.slice()
    //console.log(datas);

    let filter_model = tableT.filter_model
    if (isNotEmpty(filter_model)) {
        datas = datas.filter((item) => {
            return item.model.indexOf(filter_model) === 0
        })
    }

    const prop = column.prop
    const order = column.order

    let datas_sorted = []
    let target_value = ''

    datas.forEach((item, index) => {
        if (isNotEmpty(item[prop]) && target_value === '') {
            target_value = item[prop]
            return;
        }
    })

    if (isNumber(target_value)) {
        datas_sorted = datas.sort((a, b) => {
            if (order == "descending") {
                let index_1 = isNotEmpty(b[prop]) ? toNumber(b[prop]) : 0
                let index_2 = isNotEmpty(a[prop]) ? toNumber(a[prop]) : 0

                return index_1 - index_2
            } else if (order == "ascending") {
                let index_1 = isNotEmpty(a[prop]) ? toNumber(a[prop]) : 0
                let index_2 = isNotEmpty(b[prop]) ? toNumber(b[prop]) : 0

                return index_1 - index_2
            }
        })
    } else {
        datas_sorted = datas.sort(function (a, b) {
            var x = a[prop].toLowerCase();
            var y = b[prop].toLowerCase();

            if (order == "descending") {
                if (x > y) { return -1; }
                if (x < y) { return 1; }
                return 0
            } else if (order == "ascending") {
                if (x < y) { return -1; }
                if (x > y) { return 1; }
                return 0
            }
        })
    }
    tableT.pageTableData = datas_sorted
    //显示数据计算
    const page_size = pageInfo.page_size
    const current_page = pageInfo.current_page
    const start = page_size * (current_page - 1)

    tableT.tableData = datas_sorted.slice(start, start + page_size)
}
function getSummaries(param) {
    const { columns, data } = param
    let total = props.totalData[0];
    let sums = [];
    if (total) {
        columns.forEach((column, index) => {
            sums[index] = total[column.property]
        })
    }
    return sums
}

function handleCurrentChange(current_page) {
    //条件筛选
    let datas = SKUCostModelFilter()

    const page_size = pageInfo.page_size
    const start = page_size * (current_page - 1)

    pageInfo.current_page = current_page
    pageInfo.total = datas.length
    tableT.tableData = datas.slice(start, start + page_size)
}
function SKUCostModelFilter() {
    let datas = tableT.pageTableData.slice()

    let filter_model = tableT.filter_model
    if (isNotEmpty(filter_model)) {
        let filter_model_lower = filter_model.toLowerCase()
        let obj_sell_name_keys = []

        if(filter_model.indexOf(",") === -1){
            //单个 模糊查询
            datas = datas.filter((item) => {
                let flag = false
                let item_model = item.model.toLowerCase()
                
                flag = item_model.indexOf(filter_model_lower) === 0

                if(flag === false){
                    //获取所有的sell name
                    if(obj_sell_name_keys.length === 0){
                        Object.keys(item).forEach(key => {
                            if(key.indexOf("_sell_name") !== -1){
                                obj_sell_name_keys.push(key)
                            }
                        });
                    }
                    
                    for (let i = 0, len = obj_sell_name_keys.length; i < len; i++) {
                        let key = obj_sell_name_keys[i]
                        let value = item[key]
                        if(isNotEmpty(value)){
                            let item_sell_name_lower = value.toLowerCase()

                            flag = (item_sell_name_lower.indexOf(filter_model_lower) === 0)
                            if(flag){
                                break
                            }
                        }
                    }
                }
                
                return flag
            })
        }else{
            //多个使用","分割
            let filterModels = filter_model.split(",")
            datas = datas.filter((item) => {
                let flag = false
                let item_model = item.model.toLowerCase()

                for (let i = 0, len = filterModels.length; i < len; i++) {
                    let filter_model_lower = filterModels[i].toLowerCase()
                    flag = (item_model === filter_model_lower)
                    if(flag){
                        break
                    }else{
                        //获取所有的sell name
                        if(obj_sell_name_keys.length === 0){
                            Object.keys(item).forEach(key => {
                                if(key.indexOf("_sell_name") !== -1){
                                    obj_sell_name_keys.push(key)
                                }
                            });
                        }

                        for (let i = 0, len = obj_sell_name_keys.length; i < len; i++) {
                            let key = obj_sell_name_keys[i]
                            let value = item[key]
                            if(isNotEmpty(value)){
                                let item_sell_name_lower = value.toLowerCase()

                                flag = (item_sell_name_lower === filter_model_lower)
                                if(flag){
                                    break
                                }
                            }
                        }
                    
                        if(flag){
                            break
                        }
                    }
                }

                return flag
            })
        }
    }

    let filter_model_sort_by = tableT.filter_model_sort_by
    if (isNotEmpty(filter_model_sort_by)) {
        if (filter_model_sort_by === "bestselling") {
            datas = collectionOrderBy(datas, 'avg_month_sales_amount', 'desc');
        } else if (filter_model_sort_by === "new_to_od") {
            datas = collectionOrderBy(datas, 'create_time', 'desc');
        } else if (filter_model_sort_by === "a_z") {
            datas = collectionOrderBy(datas, 'model', 'asc');
        } else if (filter_model_sort_by === "z_a") {
            datas = collectionOrderBy(datas, 'model', 'desc');
        }
    }

    return datas
}

function handleFullScreen(){
    page_info.tableDataDialogVisible = true
}
function handleExportExcel() {
    let datas = props.data.slice()

    if(props.title === "Seller SKU And Wholesale Cost" && isNotEmpty(tableT.filter_model)){
        //条件筛选
        datas = SKUCostModelFilter()
    }

    if (datas.length > 0) {
        let tableHeader = []
        let dataList = []

        datas.forEach((item, index) => {
            let data_sorve = []
            props.columns.forEach((k_item, k_index) => {
                let childTable = k_item.childTable
                if (isNotEmpty(childTable)) {
                    //多级表头导出
                    let first_lable = k_item.label

                    childTable.forEach((it) => {
                        if (index == 0) {
                            tableHeader.push(first_lable + ' ' + it.label)
                        }
                        const column = it.column
                        const dataStyle = it.dataStyle

                        let value = item[column]
                        if (dataStyle == "text" || dataStyle == "integer") {
                            value = value
                        } else if (dataStyle == "money") {
                            value = moenyFmt(NumericTothreeDigit(value))
                        } else if (dataStyle == "percent") {
                            value = percentFmt(value)
                        }
                        data_sorve.push(value)
                    })
                } else {
                    //基础表头导出
                    if (index == 0) {
                        tableHeader.push(k_item.label)
                    }
                    const column = k_item.column
                    const dataStyle = k_item.dataStyle

                    let value = item[column]
                    if (dataStyle == "text" || dataStyle == "integer") {
                        value = value
                    } else if (dataStyle == "money") {
                        value = moenyFmt(NumericTothreeDigit(value))
                    } else if (dataStyle == "percent") {
                        value = percentFmt(value)
                    }
                    data_sorve.push(value)
                }
            })
            dataList.push(data_sorve);
        })

        const excel_date_fmt = new moment().format('YYYY-MM-DD')
        var excelTitle = props.title

        let totalData = props.totalData[0]
        if (isNotEmpty(totalData)) {
            let totalData_sorve = []
            props.columns.forEach((k_item, k_index) => {
                const column = k_item.column
                let value = totalData[column] + ""

                totalData_sorve.push(value)
            })
            dataList.push(totalData_sorve)
        }

        dataToExcel(excelTitle + '_' + excel_date_fmt, [tableHeader], dataList)
    } else {
        ElMessage({
            message: "No data",
            type: "warn"
        })
    }
}

function handleExportSKUDetailsExcel() {
    let datas = page_info.skusDetailDatas.slice()
    let columns = page_info.skusDetailColumns.slice()

    if (datas.length > 0) {
        let tableHeader = []
        let dataList = []

        datas.forEach((item, index) => {
            let data_sorve = []
            columns.forEach((k_item, k_index) => {
                //基础表头导出
                if (index == 0) {
                    tableHeader.push(k_item.label)
                }
                const column = k_item.column
                const dataStyle = k_item.dataStyle

                let value = item[column]
                if (dataStyle == "text" || dataStyle == "integer") {
                    value = value
                } else if (dataStyle == "money") {
                    value = moenyFmt(NumericTothreeDigit(value))
                } else if (dataStyle == "percent") {
                    value = percentFmt(value)
                }
                data_sorve.push(value)
            })
            dataList.push(data_sorve);
        })

        const excel_date_fmt = new moment().format('YYYY-MM-DD')
        var excelTitle = "SKUs by Channels and Category Detail"

        dataToExcel(excelTitle + '_' + excel_date_fmt, [tableHeader], dataList)
    } else {
        ElMessage({
            message: "No data",
            type: "warn"
        })
    }
}
async function viewSkusByCategory(item, row) {
    page_info.page_data_loading = true
    let column = item.column

    let contact_id = column.split('_')[0]
    let sku_type = column.split('_')[1]

    if (isEmpty(sku_type)) {
        sku_type = ""
    }
    let category = row.categories
    let params = { contact_id, sku_type, category }

    await getSkusByCategoryDetail(params).then((res) => {
        let data = res.data
        page_info.skusDetailDialogVisible = true

        page_info.skusDetailCrawlingUpdateTime = data.update_time
        page_info.skusDetailDatas = data.skus_by_category_details
    }).finally(() => {
        page_info.page_data_loading = false
    })
}
function viewLink(item, row) {
    let c_id = item.c_id
    let channel_view_url = row[c_id + '_channel_view_url']

    if (isNotEmpty(channel_view_url)) {
        window.open(channel_view_url, '_blank')
    } else {
        ElMessage({
            message: "Channel View URL does't setting",
            type: "warn"
        })
    }
}

function filterTableData() {
    handleCurrentChange(1)
}
function handleModelSortBy() {
    handleCurrentChange(1)
}

function open_multiline_box(multiline_type) {
    //model multiline click
    let model_names = tableT.filter_model
    page_info.multiline_box = model_names.replaceAll(",", "\n");
    page_info.dialogMultilineVisible = true;
}
function checkMutilineInput() {
    let multiline_box = page_info.multiline_box;
    page_info.multiline_box = multiline_box.replaceAll(" ", "");
}
function saveMultiline() {
    let multiline_box = page_info.multiline_box;
    multiline_box = multiline_box.replaceAll(/\n+/g, ",");
    //去重
    let multiline_value = [];
    multiline_box.split(",").forEach((item, index) => {
        if (multiline_value.indexOf(item.trim()) == -1) {
            multiline_value.push(item.trim());
        }
    });
    multiline_box = multiline_value.join(",");

    //model multiline click
    tableT.filter_model = multiline_box;
    page_info.dialogMultilineVisible = false;

    filterTableData();
}
</script>

<style scoped>
:deep(.el-table__cell .cell) {
    padding: 0 5px !important;
}

:deep(.el-form-item) {
    margin-bottom: 0px;
}

:deep(.el-form--inline .el-form-item) {
    margin-right: 0px;
}

:deep(.el-table--striped .el-table__body tr.el-table__row--striped td) {
    background-color: #fef6f1;
}

:deep(.el-table__row--level-1) {
    background-color: #f2f2f2;
}
:deep(.el-input-group__append),
:deep(.el-input-group__prepend) {
	cursor: pointer;
	padding: 0 5px;
}

.el-table>>>.headerStyle th,
:deep(.el-table thead.is-group th.el-table__cell) {
    font-size: 13px;
    color: #000;
    background-color: #e8c2a8;
}

:deep() .el-table .el-table__footer-wrapper .el-table__footer tr td {
    background-color: #f2f2f2;
}

.ads_promo_box {
    width: 24px;
    color: #FFC62D;
    font-size: 22px;
    font-weight: bold;
}

:deep(.totalClass .cell),
:deep(.totalClass .cell span) {
    font-weight: 700;
}
</style>